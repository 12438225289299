<template>
  <div class="teacher">
    <list-template
        :loading="loading"
        :total="total"
        :table-data="tableData"
        :table-config="tableConfig"
        :search-config="searchConfig"
        :current-page="page"
        @onSearch="onSearch"
        @onReset="search = null"
        @onChangePage="changeCurrentPage"
        @onHandle="tableHandle"
        @onAdd="$router.push('./add')"
        has-add
    ></list-template>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading:true,
      // 表格搜索配置
      searchConfig: [
        {
          prop: 'filter[and][0][grade_id]',
          tag:"select",
          placeholder: '筛选年级',
          options:[],
          label:"name",
          value:"id"
        }
      ],
      // 表格配置
      tableConfig: [
        {
          prop: 'name',
          label: '校历名称'
        },
        {
          prop: 'start_at',
          label: '有效期开始时间'
        },
        {
          prop: 'end_at',
          label: '有效期结束时间'
        },
        {
          prop: 'roleType',
          label: '适用角色类型'
        },
        {
          prop: 'gradeName',
          label: '应用年级'
        },
        {
          label: '操作',
          handle: true,
          buttons: [{ type:"edit", text:"编辑" },{ type:"delete", text:"删除" }]
        }
      ],
      search: null,
      total: 0,
      // 表格中的数据
      tableData: []
    }
  },
  mounted() {
    this.$store.commit("setPage",1);
    this.getData()
  },
  activated() {
    this.getData()
  },
  computed:{
    ...mapState(["page"])
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit("setPage",e);
      this.getData()
    },
    /**
     * 获取数据
     */
    getData() {
      let { search, page } = this
      // 获取年级列表
      this.$utils.commonApi.getGradeList().then(res=>{
        this.searchConfig[0].options = res.data.data;
      })
      // 获取校历数据
      this.loading = true;
      this.$_axios.get('/school-calendar', { params: { ...search, page,sort:"-created_at" } }).then(res => {
        let { data } = res.data
        this.tableData = data
        let { total } = this.$tools.getPaginationInfo(res.headers)
        this.total = total
      }).finally(()=>this.loading = false);
    },
    // 搜索按钮被点击
    onSearch(val) {
      this.$store.commit("setPage",1);
      this.search = val
      this.getData()
    },
    // 表格后面操作被点击
    tableHandle(row, handle) {
      const { type } = handle
      if(type === "delete") {
        this.$confirm("删除后将无法恢复, 确认删除?","提示").then(res=>{
          this.$_axios.delete("/school-calendar/"+row.id,{
            params:{
              version:row.version
            }
          }).then(res=>{
            this.$message.success("已删除");
            this.getData();
          })
        })
      } else if(type === "edit") {
        this.$router.push({ path: './edit', query: { id: row.id } })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.list {
  padding: 24rem 0 0 32rem;
  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }
  .pagination {
    margin-top: 50rem;
  }

}
</style>
